import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Typed from 'react-typed';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// Components
import Layout from '../components/layout/Layout';
import LogoSimulatorSection from '../components/section/simulator/Logo';
import LogoPlansSection from '../components/section/plans/Logo';
import RealisationsSection from '../components/section/Realisations';
import TestimonialsSection from '../components/section/Testimonials';

// Images
import backgroundImage from '../images/svg/components/wave-pattern-light.svg';

const LogoPage = ({ data }) => {

  const { t } = useTranslation();

  // Tawk Toggle
  const handleClickTawk = useCallback(
    e => {
      e.preventDefault();
      window.Tawk_API.toggle();
    },
    []
  );

  return (
    <Layout
      pageType="classic"
      pageTitle={t('meta.title')}
      pageDescription={t('meta.description')}
      pageKeywords={t('meta.keywords')}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide"
      headerStyle="light"
    >
      <div>
        <div className="gradient-y-gray position-relative" data-activity-plugin="aos">
          <div className="content-space-t-3 content-space-t-lg-5 content-space-b-2 content-space-b-lg-5">
            <div className="container">
              <div className="row justify-content-md-between align-items-sm-center">
                <div className="col-sm-6 col-md-5 mb-5 mb-sm-0">
                  <StaticImage className="img-fluid" quality="30" src="../images/illustrations/logo/la-small-agency-logo.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                </div>
                <div className="col-sm-6">
                  <div className="mb-5">
                    <h1 className="display-4 mb-3">{t('title')}
                      <br></br>
                      <span className="text-primary text-highlight-behind-warning">
                        <Typed
                          strings={t('titleTyped').split(',')}
                          typeSpeed={40}
                          backSpeed={50}
                          loop >
                        </Typed>
                      </span>
                    </h1>
                    <p className="lead">{t('subtitle')}</p>
                  </div>
                  <div className="d-grid d-sm-flex gap-3">
                    <Link className="btn btn-primary btn-transition" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-bottom zi-1" style={{ marginBottom: '-.125rem' }}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
              <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
            </svg>
          </div>
        </div>
        <div className="gradient-y-sm-primary overflow-hidden" id="checklist">
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2 dangerouslySetInnerHTML={{ __html: t('checklist.title') }}></h2>
            </div>
            <div className="w-md-100 mx-md-auto">
              <div className="row justify-content-lg-center align-items-md-center">
                <div className="col-12 col-md-4 mb-7 m-md-0">
                  <div className="position-relative">
                    <div className="card">
                      <div className="card-body">
                        <span className="svg-icon text-primary mb-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5 4C20.3284 4 21 4.67157 21 5.5V17.5C21 18.3284 20.3284 19 19.5 19H4.5C3.67157 19 3 18.3284 3 17.5V14.5C3 13.6716 3.67157 13 4.5 13H9V9.5C9 8.67157 9.67157 8 10.5 8H15V5.5C15 4.67157 15.6716 4 16.5 4H19.5Z" fill="#035A4B"></path>                          </svg>
                        </span>
                        <h3 className="card-title">{t('checklist.card.title')}</h3>
                        <p className="card-text">{t('checklist.card.subtitle')}</p>
                      </div>
                    </div>
                    <figure className="position-absolute bottom-0 start-0 zi-n1 mb-n7 ms-n7" style={{ width: '12rem' }}>
                      <StaticImage className="img-fluid" quality="30" src="../images/svg/components/dots-lg.svg" alt={t('checklist.image.alt', { title: t('common.image.alt') })} />
                    </figure>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="row justify-content-lg-center align-items-md-center">
                    <div className="col-6 col-lg-3">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.restaurant')}</li>
                        <li className="list-checked-item">{t('checklist.cafe')}</li>
                        <li className="list-checked-item">{t('checklist.bar')}</li>
                        <li className="list-checked-item">{t('checklist.bakery')}</li>
                        <li className="list-checked-item">{t('checklist.bookStore')}</li>
                        <li className="list-checked-item">{t('checklist.concertVenue')}</li>
                      </ul>
                    </div>
                    <div className="col-6 col-lg-3">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.grocery')}</li>
                        <li className="list-checked-item">{t('checklist.hotel')}</li>
                        <li className="list-checked-item">{t('checklist.movieTheatre')}</li>
                        <li className="list-checked-item">{t('checklist.museum')}</li>
                        <li className="list-checked-item">{t('checklist.artGallery')}</li>
                        <li className="list-checked-item">{t('checklist.realEstate')}</li>
                        <li className="list-checked-item">{t('checklist.school')}</li>
                      </ul>
                    </div>
                    <div className="col-6 col-lg-3">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.spas')}</li>
                        <li className="list-checked-item">{t('checklist.personalCare')}</li>
                        <li className="list-checked-item">{t('checklist.automobiles')}</li>
                        <li className="list-checked-item">{t('checklist.consulting')}</li>
                        <li className="list-checked-item">{t('checklist.nutritionist')}</li>
                        <li className="list-checked-item">{t('checklist.beauty')}</li>
                      </ul>
                    </div>
                    <div className="col-6 col-lg-3">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.insurance')}</li>
                        <li className="list-checked-item">{t('checklist.software')}</li>
                        <li className="list-checked-item">{t('checklist.legal')}</li>
                        <li className="list-checked-item">{t('checklist.nonProfitOrganization')}</li>
                        <li className="list-checked-item">{t('checklist.media')}</li>
                        <li className="list-checked-item">{t('checklist.travel')}</li>
                      </ul>
                    </div>
                  </div>
                  <AnchorLink className="link" href="#plans">{t('cta.ourPlans')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2 dangerouslySetInnerHTML={{ __html: t('process.title') }}></h2>
          </div>
          <div className="row align-items-lg-center">
            <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
              <div>
                <div className="nav nav-sm nav-pills nav-pills-shadow flex-lg-column gap-lg-1 p-3 row" role="tablist">
                  <a className="nav-item nav-link col-12 active" href="#process-conversation" id="process-conversation-tab" data-bs-toggle="tab" data-bs-target="#process-conversation" role="tab" aria-controls="process-conversation" aria-selected="true" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z" fill="#035A4B"></path>
                          <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.conversation.title')}</div>
                        <p className="text-body mb-0">{t('process.conversation.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-research" id="process-research-tab" data-bs-toggle="tab" data-bs-target="#process-research" role="tab" aria-controls="process-research" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="#035A4B"></path>
                          <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.research.title')}</div>
                        <p className="text-body mb-0">{t('process.research.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-propose" id="process-propose-tab" data-bs-toggle="tab" data-bs-target="#process-propose" role="tab" aria-controls="process-propose" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z" fill="#035A4B"></path>
                          <path d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.propose.title')}</div>
                        <p className="text-body mb-0">{t('process.propose.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-create" id="process-create-tab" data-bs-toggle="tab" data-bs-target="#process-create" role="tab" aria-controls="process-create" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M6.45801 14.775L9.22501 17.542C11.1559 16.3304 12.9585 14.9255 14.605 13.349L10.651 9.39502C9.07452 11.0415 7.66962 12.8441 6.45801 14.775V14.775Z" fill="#035A4B"></path>
                          <path d="M9.19301 17.51C9.03401 19.936 6.76701 21.196 3.55701 21.935C3.34699 21.9838 3.12802 21.9782 2.92074 21.9189C2.71346 21.8596 2.52471 21.7484 2.37231 21.5959C2.2199 21.4434 2.10886 21.2545 2.04967 21.0472C1.99048 20.8399 1.98509 20.6209 2.034 20.411C2.772 17.201 4.03401 14.934 6.45801 14.775L9.19301 17.51ZM21.768 4.43697C21.9476 4.13006 22.0204 3.77232 21.9751 3.41963C21.9297 3.06694 21.7687 2.73919 21.5172 2.48775C21.2658 2.2363 20.9381 2.07524 20.5854 2.02986C20.2327 1.98449 19.8749 2.0574 19.568 2.23701C16.2817 4.20292 13.2827 6.61333 10.656 9.39998L14.61 13.354C17.395 10.7252 19.8037 7.72455 21.768 4.43697V4.43697Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.create.title')}</div>
                        <p className="text-body mb-0">{t('process.create.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-deliver" id="process-deliver-tab" data-bs-toggle="tab" data-bs-target="#process-deliver" role="tab" aria-controls="process-deliver" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M4.85714 1H11.7364C12.0911 1 12.4343 1.12568 12.7051 1.35474L17.4687 5.38394C17.8057 5.66895 18 6.08788 18 6.5292V19.0833C18 20.8739 17.9796 21 16.1429 21H4.85714C3.02045 21 3 20.8739 3 19.0833V2.91667C3 1.12612 3.02045 1 4.85714 1ZM7 13C7 12.4477 7.44772 12 8 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H8C7.44772 14 7 13.5523 7 13ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H8Z" fill="#035A4B"></path>
                          <path fillRule="evenodd" clipRule="evenodd" d="M6.85714 3H14.7364C15.0911 3 15.4343 3.12568 15.7051 3.35474L20.4687 7.38394C20.8057 7.66895 21 8.08788 21 8.5292V21.0833C21 22.8739 20.9796 23 19.1429 23H6.85714C5.02045 23 5 22.8739 5 21.0833V4.91667C5 3.12612 5.02045 3 6.85714 3ZM7 13C7 12.4477 7.44772 12 8 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H8C7.44772 14 7 13.5523 7 13ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H8Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.deliver.title')}</div>
                        <p className="text-body mb-0">{t('process.deliver.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 order-lg-1">
              <div className="tab-content">
                <div className="tab-pane fade show active" id="process-conversation" role="tabpanel" aria-labelledby="processOne-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/logo/la-small-agency-logo-process-conversation.svg" alt={t('process.conversation.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.conversation.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-research" role="tabpanel" aria-labelledby="process-research-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/logo/la-small-agency-logo-process-research.svg" alt={t('process.research.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.research.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-propose" role="tabpanel" aria-labelledby="process-propose-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/logo/la-small-agency-logo-process-propose.svg" alt={t('process.propose.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.propose.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-create" role="tabpanel" aria-labelledby="process-create-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/logo/la-small-agency-logo-process-create.svg" alt={t('process.create.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.create.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-deliver" role="tabpanel" aria-labelledby="process-deliver-tab">
                  <StaticImage className="device-mobile-img" quality="30" src="../images/illustrations/logo/la-small-agency-logo-process-deliver.svg" alt={t('process.deliver.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.deliver.text')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mx-md-auto mt-2 mb-5 mb-md-9" data-aos="fade-up">
            <button className="btn btn-primary btn-transition" onClick={handleClickTawk}><i className="bi bi-chat me-1"></i>{t('cta.letsTalk')}</button>
          </div>
        </div>
        <div className="position-relative bg-dark" style={{ backgroundImage: 'url(' + backgroundImage + ')' }} data-aos="zoom-in">
          <div className="container content-space-t-2 content-space-b-1 position-relative zi-2">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2 className="text-white">{t('benefits.title')}</h2>
              <p className="text-white-70">{t('benefits.subtitle')}</p>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-3 mb-3 mb-sm-7">
                <span className="svg-icon text-white mb-3">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z" fill="#035A4B"></path>
                    <path d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z" fill="#035A4B"></path>
                  </svg>
                </span>
                <div className="h4 text-white">{t('benefits.identity.title')}</div>
                <p className="text-white-70">{t('benefits.identity.subtitle')}</p>
              </div>
              <div className="col-sm-6 col-md-3 mb-3 mb-sm-7">
                <span className="svg-icon text-white mb-3">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="#035A4B"></path>
                    <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="#035A4B"></path>
                  </svg>
                </span>
                <div className="h4 text-white">{t('benefits.personalized.title')}</div>
                <p className="text-white-70">{t('benefits.personalized.subtitle')}</p>
              </div>
              <div className="col-sm-6 col-md-3 mb-3 mb-sm-7">
                <span className="svg-icon text-white mb-3">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="#035A4B"></path>
                    <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="#035A4B"></path>
                  </svg>
                </span>
                <div className="h4 text-white">{t('benefits.optimized.title')}</div>
                <p className="text-white-70">{t('benefits.optimized.subtitle')}</p>
              </div>
              <div className="col-sm-6 col-md-3 mb-3 mb-sm-7 mb-md-0">
                <span className="svg-icon text-white mb-3">
                  <span className="svg-icon text-white mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M8.70001 6C8.10001 5.7 7.39999 5.40001 6.79999 5.10001C7.79999 4.00001 8.90001 3 10.1 2.2C10.7 2.1 11.4 2 12 2C12.7 2 13.3 2.1 13.9 2.2C12 3.2 10.2 4.5 8.70001 6ZM12 8.39999C13.9 6.59999 16.2 5.30001 18.7 4.60001C18.1 4.00001 17.4 3.6 16.7 3.2C14.4 4.1 12.2 5.40001 10.5 7.10001C11 7.50001 11.5 7.89999 12 8.39999Z" fill="#035A4B"></path>
                      <path d="M7 20C7 20.2 7 20.4 7 20.6C6.2 20.1 5.49999 19.6 4.89999 19C4.59999 18 4.00001 17.2 3.20001 16.6C2.80001 15.8 2.49999 15 2.29999 14.1C4.99999 14.7 7 17.1 7 20ZM10.6 9.89995C8.70001 8.09995 6.39999 6.89996 3.79999 6.29996C3.39999 6.89996 2.99999 7.49995 2.79999 8.19995C5.39999 8.59995 7.7 9.79996 9.5 11.6C9.8 10.9 10.2 10.3999 10.6 9.89995ZM2.20001 10.1C2.10001 10.7 2 11.4 2 12C2 12 2 12 2 12.1C4.3 12.4 6.40001 13.7 7.60001 15.6C7.80001 14.8 8.09999 14.0999 8.39999 13.3999C6.89999 11.5999 4.70001 10.4 2.20001 10.1ZM11 20C11 14 15.4 8.99996 21.2 8.09996C20.9 7.39996 20.6 6.79995 20.2 6.19995C13.8 7.49995 9 13.0999 9 19.8999C9 20.3999 9.00001 21 9.10001 21.5C9.80001 21.7 10.5 21.7999 11.2 21.8999C11.1 21.2999 11 20.7 11 20ZM19.1 19C19.4 18 20 17.2 20.8 16.6C21.2 15.8 21.5 15 21.7 14.1C19 14.7 16.9 17.1 16.9 20C16.9 20.2 16.9 20.4 16.9 20.6C17.8 20.2 18.5 19.6 19.1 19ZM15 20C15 15.9 18.1 12.6 22 12.1C22 12.1 22 12.1 22 12C22 11.3 21.9 10.7 21.8 10.1C16.8 10.7 13 14.9 13 20C13 20.7 13.1 21.2999 13.2 21.8999C13.9 21.7999 14.5 21.7 15.2 21.5C15.1 21 15 20.5 15 20Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                </span>
                <div className="h4 text-white">{t('benefits.modernity.title')}</div>
                <p className="text-white-70">{t('benefits.modernity.subtitle')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light">
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('simulator.title')}</h2>
              <p>{t('simulator.subtitle')}</p>
            </div>
            <LogoSimulatorSection data={data} plan={data.plan.frontmatter} service="logo" />
          </div>
        </div>
        <div className="container content-space-2 content-space-lg-3">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('plans.title')}</h2>
            <p>{t('plans.subtitle')}</p>
          </div>
          <LogoPlansSection data={data} plan={data.plan.frontmatter} service="logo" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        {data.realisations.nodes.length ?
          <div className="bg-light">
            <div className="container content-space-2 content-space-lg-3">
              <div className="w-md-75 text-center mx-md-auto mb-5">
                <h2>{t('realisations.title', { title: data.site.siteMetadata.title })}</h2>
                <p>{t('realisations.subtitle', { title: data.site.siteMetadata.title })}</p>
              </div>
              <RealisationsSection data={data} realisations={data.realisations} service="home" />
              <div className="text-center aos-init aos-animate" data-aos="fade-up">
                <div className="card card-info-link card-sm mb-5 mb-md-0">
                  <div className="card-body">
                    <Link className="card-link ms-2" to="/realisations/">{t('realisations.button')} <span className="bi-chevron-right small ms-1"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}
        {data.testimonials.nodes.length ?
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('testimonials.title')}</h2>
            </div>
            <TestimonialsSection data={data} testimonials={data.testimonials} sectionStyle="1" service="home" />
            <div className="text-center aos-init aos-animate" data-aos="fade-up">
              <div className="card card-info-link card-sm mb-5 mb-md-0">
                <div className="card-body">
                  {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
            </div>
          </div>
          : null}
        <div className="container content-space-2 content-space-b-lg-4">
          <div className="w-lg-75 mx-lg-auto">
            <div className="card card-sm overflow-hidden">
              <div className="card-body">
                <div className="row justify-content-md-start align-items-md-center text-center text-md-start">
                  <div className="col-md offset-md-3 mb-3 mb-md-0">
                    <h4 className="card-title">{t('cta.title')}</h4>
                  </div>
                  <div className="col-md-auto">
                    <Link className="btn btn-primary btn-transition" to="/contact/"><i className="bi bi-envelope me-1"></i>{t('cta.getStarted')}</Link>
                  </div>
                </div>
                <figure className="w-25 d-none d-md-block position-absolute top-0 start-0 mt-n2">
                  <svg viewBox="0 0 451 902" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.125" d="M0 82C203.8 82 369 247.2 369 451C369 654.8 203.8 820 0 820" stroke="url(#paint2_linear)" strokeWidth="164" strokeMiterlimit="10"></path>
                    <defs>
                      <linearGradient id="paint2_linear" x1="323.205" y1="785.242" x2="-97.6164" y2="56.3589" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="white" stopOpacity="0"></stop>
                        <stop offset="1" stopColor="#377dff"></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LogoPage

export const query = graphql`
  query ($language: String!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "logo", "plans", "simulator-logo", "categories", "tools", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    realisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { services: { in: ["logo"] }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          technologies
          languages
          description
          client
          partners
          services
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    testimonials: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/testimonials/" },
        frontmatter: { pages: {services: { in: ["logo"] } }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
    )
    {
      nodes {
        frontmatter {
          name
          rating
          from
          link
          pages {
            services
            realisations
          }
          active
          language
          date(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        slug
        body
      }
    }
    plan: mdx(slug: {eq: "logo"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        custom {
          mockups {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          feedbacks {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
          graphic_charter {
              active
              included
              active_price
          }
          delivered {
              active
              create
              mockup
              feedback
              graphic_charter
          }
        }
      }
      id
    }    
  }
`;
